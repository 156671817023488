import { Capacitor } from '@capacitor/core';
import { useConfig } from '@travelwin/core';
import Cookies from 'js-cookie';
import { omit } from 'radash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet-async';

import { useConsent } from '../../context/Consent';
import { useUser } from '../../context/User';
import { sendEvent } from '../../services/facebook/FacebookService';

declare global {
  interface Window {
    fbq?: (...args: unknown[]) => void;
  }
  const fbq: (...args: any[]) => void;
}

const isNativePlatform = Capacitor.isNativePlatform();

export interface FacebookEvent {
  event_id: string;
  event_name: string;
  email?: string;
  content_name?: string;
  content_type?: string;
  content_ids?: string[];
  currency?: string;
  value?: number;
}

export type MetaPixelAttributes = {
  fbc?: string;
  fbp?: string;
  external_id?: string;
};

const sha256 = async (message: string) => {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

export const getMetaPixelAttributes = async (
  email?: string,
): Promise<MetaPixelAttributes> => ({
  fbc: Cookies.get('_fbc'),
  fbp: Cookies.get('_fbp'),
  external_id: email ? await sha256(email) : undefined,
});

export const metaPixelAttributesToHeaders = (attributes: MetaPixelAttributes) =>
  Object.entries({
    'X-FB-FBC': attributes.fbc,
    'X-FB-FBP': attributes.fbp,
  }).reduce<Record<string, string>>((obj, [key, value]) => {
    if (value !== undefined) {
      obj[key] = value;
    }
    return obj;
  }, {});

export const trackMetaPixel = async (event: FacebookEvent, server = true) => {
  if (typeof window !== 'undefined' && window.fbq) {
    const metaPixelAttributes = await getMetaPixelAttributes(event.email);
    const eventData = omit(event, ['event_id', 'event_name', 'email']);
    window.fbq?.(
      'track',
      event.event_name,
      {
        ...eventData,
        ...metaPixelAttributes,
      },
      { event_id: event.event_id },
    );
    if (server) {
      await sendEvent(event);
    }
  }
};

const MetaPixelHelmet = () => (
  <Helmet>
    <script>{`
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = true;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    fbq.disablePushState = true;
    fbq.allowDuplicatePageViews = true;`}</script>
  </Helmet>
);

export const MetaPixelTracker = () => {
  const { META_PIXEL_ID } = useConfig();
  const { consent } = useConsent();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    if (META_PIXEL_ID && window.fbq && !isNativePlatform && !initialized) {
      if (consent.marketing) {
        window.fbq('init', META_PIXEL_ID);
        window.fbq('consent', 'grant');
        setInitialized(true);
        if (typeof fbq === 'function') {
          (fbq as any).disablePushState = true;
          (fbq as any).allowDuplicatePageViews = true;
        }
      }
    }
  }, [META_PIXEL_ID, consent]);

  useEffect(() => {
    if (initialized) {
      trackMetaPixel({
        event_id: uuidv4(),
        event_name: 'PageView',
        email: user?.email.trim().toLowerCase(),
      });
    }
  }, [location.pathname, initialized]);

  return <MetaPixelHelmet />;
};
