import {
  FacebookEvent,
  getMetaPixelAttributes,
  metaPixelAttributesToHeaders,
} from '../../components/MetaPixel';
import { request } from '../helpers/requests';

const baseUri = 'ecommerce';

export const sendEvent = async (event: FacebookEvent): Promise<void> => {
  const metaPixelAttributes = await getMetaPixelAttributes(event.email);
  const options = {
    method: 'POST',
    headers: metaPixelAttributesToHeaders(metaPixelAttributes),
    body: JSON.stringify(event),
  };
  return request(`${baseUri}/facebook_event`, options);
};
